import { FormattedMessage } from 'react-intl'
import {
  UserOutlined,
  CheckCircleOutlined,
  BulbOutlined,
  CarryOutOutlined,
  CalendarOutlined,
  BookOutlined,
  InboxOutlined,
  ContainerOutlined,
  DollarCircleOutlined,
  ContactsOutlined
} from '@ant-design/icons'
import { NavItemType } from 'types/menu'

const aplicaciones: NavItemType = {
  id: 'applications',
  title: <FormattedMessage id="applications" />,
  type: 'group',
  children: [
    {
      id: 'time-registration',
      title: <FormattedMessage id="time-registration" />,
      type: 'item',
      url: '/time-registration',
      icon: CalendarOutlined
    },
    {
      id: 'customers',
      title: <FormattedMessage id="customers" />,
      type: 'item',
      url: '/customer/list',
      icon: UserOutlined
    },
    {
      id: 'environmentProject',
      title: <FormattedMessage id="environmentProject" />,
      type: 'item',
      url: '/environment-project',
      icon: ContainerOutlined,
      breadcrumbs: false
    },
    {
      id: 'inbox',
      title: <FormattedMessage id="inbox" />,
      type: 'collapse',
      icon: InboxOutlined,
      url: '/inbox',
      children: [
        {
          id: 'customerReport',
          title: <FormattedMessage id="customers" />,
          type: 'item',
          url: '/inbox/clientes',
          breadcrumbs: false
        },
        {
          id: 'diPendientes',
          title: <FormattedMessage id="di-pending" />,
          type: 'item',
          url: '/inbox/di-pending',
          breadcrumbs: false
        },
        {
          id: 'proyectoAprobacion',
          title: <FormattedMessage id="project-approbation" />,
          type: 'item',
          url: '/inbox/project-approbation',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'projects',
      title: <FormattedMessage id="projects" />,
      type: 'item',
      url: '/project/list',
      icon: BulbOutlined
    },
    {
      id: 'reports',
      title: <FormattedMessage id="reports" />,
      type: 'item',
      url: '/reports',
      icon: BookOutlined
    },
    {
      id: 'planning',
      title: <FormattedMessage id="planning" />,
      type: 'item',
      url: '/planning/list',
      icon: CarryOutOutlined,
      disabled: true
    },
    {
      id: 'bizagi',
      title: <FormattedMessage id="bizagi" />,
      type: 'item',
      url: '/bizagi',
      icon: CheckCircleOutlined
    },
    {
      id: 'solicitud-facturacion',
      title: <FormattedMessage id="solicitud-facturacion" />,
      type: 'item',
      url: '/solicitud-facturacion',
      icon: DollarCircleOutlined
    },
    {
      id: 'proveedores',
      title: 'Proveedores',
      type: 'item',
      url: '/proveedores',
      icon: ContactsOutlined
    }
  ]
}

export default aplicaciones
